const Data =  [
  {
    "id":1,
    "name":"Chirag Sharma",
    "Position":"Front-End Developer Intern ",
    "company":"Startup-Kt(Main)",
    "duration":"15th January 2023 - 15th March 2023",
    "description":"During the Internship, Chirag showed he is quick at learning new technologies, sincere and creative. We wish him the best for future endeavours",
    "img":"https://i.postimg.cc/br9VGNF7/Whats-App-Image-2023-06-13-at-23-05-11.jpg",
    "Unique-Id":"48a3b0d0-3fad-4060-b87c-dfccb3094096",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":2,
    "name":"Hari Karumuri",
    "Position":"Full-Stack Developer",
    "company":"Startup-Kt(Main)",
    "duration":"12th September 2022 - Working",
    "description":"-",
    "img":"https://i.postimg.cc/RhfZ1hm4/Whats-App-Image-2023-06-14-at-01-16-23.jpg",
    "Unique-Id":"cb8b9bd5-cc8d-4545-b89d-85f2c553ddda",
    "Nationality": "Indian",
    "Designation": "Full-Time Employee",
    "Status": "Working",
  },
  {
    "id":3,
    "name":"Navin Patidar",
    "Position":"Front-End Developer Intern ",
    "company":"Startup-Kt",
    "duration":"1st July 2023 - 31st August 2023",
    "description":"Throughout the Internship, Navin consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/qqH17H9D/Whats-App-Image-2023-06-14-at-23-11-56.jpg",
    "Unique-Id":"4da5a5a6-c4d0-4bbf-a2f7-481a78406d99",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":4,
    "name":"Aditya Ajit Upadhye",
    "Position":"Front-End Developer Intern ",
    "company":"Startup-Kt",
    "duration":"12th January,2023 - 12th March,2023",
    "description":"Throughout the Internship, Aditya consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/kMj7fz0V/IMG-9745.jpg",
    "Unique-Id":"7afbc764-20c0-4a6a-907a-ff1b4b7d781c",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":5,
    "name":"Suvadip Hazra",
    "Position":"Java Developer",
    "company":"Startup-Kt",
    "duration":"15th January,2023 - 15th March,2023",
    "description":"Throughout the Internship, Suvadip consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/3RNXKJ9H/Suvadip-img.png",
    "Unique-Id":"37be8e44-0d98-43e3-bfc3-be2d42e462c4",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":6,
    "name":"Allam Sumith Reddy",
    "Position":"Front-End Developer Intern",
    "company":"Startup-Kt",
    "duration":"10th January,2023 - 10th March,2023",
    "description":"Throughout the Internship, Sumith consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/rmGkwMpQ/SUmith-img.jpg",
    "Unique-Id":"cfd54ef1-44d1-4bdf-ac31-ce379af303ac",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":7,
    "name":"Ankita Mondal",
    "Position":"Java Developer",
    "company":"Startup-Kt",
    "duration":"11th January,2023 - 11th March,2023",
    "description":"Throughout the Internship, Ankita consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish her the best for future endeavors.",
    "img":"https://i.postimg.cc/qRK7cPv3/ankita-img.png",
    "Unique-Id":"a4a4f843-2d20-48c1-b295-c08801740171",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":8,
    "name":"Anurag Chittoria",
    "Position":"Cyber Security Intern",
    "company":"Startup-Kt",
    "duration":"11th January,2023 - 11th March,2023",
    "description":"Throughout the Internship, Anurag consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/KvftgMVy/ANurag-img.jpg",
    "Unique-Id":"49596869-5266-41fd-b35c-872f67e8292c",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":9,
    "name":"KMD Khaja",
    "Position":"Front-End Development Intern",
    "company":"Startup-Kt",
    "duration":"1st July,2023 - 30th June,2023",
    "description":"Throughout the Internship, Khaja consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/QdWxrLcn/Khaja-img.jpg",
    "Unique-Id":"1e43ad08-e2c0-4abf-9990-5c2ac0ea7c79",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":10,
    "name":"Ichitaka Yamamoto",
    "Position":"Machine Learning Intern",
    "company":"Startup-Kt",
    "duration":" 15th Jan,2023 - 15th March,2023",
    "description":"Throughout the Internship, Ichi consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/90NCxgfH/ichi-img.jpg",
    "Unique-Id":"cbbcc1b6-a9bb-4ae5-b3f1-37ce8fb5f90f",
    "Nationality": "Japanese",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":11,
    "name":"Vasistha Arvind Rathore",
    "Position":"Python Developer Intern",
    "company":"Startup-Kt",
    "duration":" 15th Jan,2023 - 15th March,2023",
    "description":"Throughout the Internship, Arvind consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/HxhCgtMt/arvindimg.jpg",
    "Unique-Id":"bd8e0fa5-5f3f-42a7-95a4-5cbfc2a07e67",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":12,
    "name":"Sachin Rao Jupally",
    "Position":"Front-End Developer Intern",
    "company":"Startup-Kt",
    "duration":" 10th Jan,2023 - 10th March,2023",
    "description":"Throughout the Internship, Sachin consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/QxVTYkqN/sachin-img.jpg",
    "Unique-Id":"f4546ecd-b385-4e50-b922-7a453c447fe6",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":13,
    "name":"Chalukya Chowdary",
    "Position":"Front-End Developer Intern",
    "company":"Startup-Kt",
    "duration":" 15th Jan,2023 - 15th March,2023",
    "description":"Throughout the Internship, Chalukya consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish her the best for future endeavors.",
    "img":"https://i.postimg.cc/k4175m0K/chalukya-img.jpg",
    "Unique-Id":"21021bf7-0047-4b8b-be28-280efb882a76",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":14,
    "name":"Hitanshi Verma",
    "Position":" Assistant Business Executive",
    "company":"Startup-Kt",
    "duration":" 15th Feb,2023 to 15th April,2023",
    "description":"Throughout the Internship, Hitanshi Verma consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish her the best for future endeavors.",
    "img":"https://i.postimg.cc/D0dbP1Tr/Hitanshi.jpg",
    "Unique-Id":"bf999b9f-fa99-48e6-a151-a54d788402eb",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":15,
    "name":"Markapuram Rama Prasad",
    "Position":"Front-End Developer Intern",
    "company":"Startup-Kt",
    "duration":"15th Jan,2023 to 15th March,2023",
    "description":"Throughout the Internship, Rama Prasad consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/dQ6qRHzW/ramprasad-img.png",
    "Unique-Id":"b6d3b0c6-7310-4812-bb54-e95fd7008ffe",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":16,
    "name":"Venkatesh Gowda",
    "Position":"Front-End Development Intern ",
    "company":"Startup-Kt",
    "duration":"15th Jan,2023 to 15th March,2023",
    "description":"Throughout the Internship, Venkatesh consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/hPnKkHLP/venkatesh-img.jpg",
    "Unique-Id":"d9534d2c-c4f2-4723-8081-8a7964bcd735",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":17,
    "name":"",
    "Position":" ",
    "company":"Startup-Kt",
    "duration":"",
    "description":"Throughout the Internship,  consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"",
    "Unique-Id":"",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  
]

export default Data;